import { Col, Row } from 'react-bootstrap'
import OnPageNotification from '@/shared/components/OnPageNotification'

function AuthLayout ({
  title,
  subtitle,
  showNotification,
  notificationTitle,
  notificationDescription,
  notificationLink,
  notificationLinkText,
  lite,
  trial,
  ...props
}) {
  return (
    <Row className="login-layout-panels" style={{ height: '100%' }}>
      {
        trial ?
          <Col className="col-md-6 col-12 border-0 d-flex justify-content-end">
            <div className={`trial-container px-4 mt-3 mt-md-5 px-md-5 `}>
            {subtitle}
            </div>
          </Col>
          : null
      }
      <Col className={`col-md-6 col-12 border-0 d-flex login-form position-relative  ${trial ? '' : ''}`}>
        <div className={`login-container p-4 p-md-5`}>
          {
            showNotification ?
              <OnPageNotification
                notificationTitle={notificationTitle}
                notificationDescription={notificationDescription}
                notificationLink={notificationLink}
                notificationLinkText={notificationLinkText}
              />
              :
              <>
                {
                  !trial ?
                    <>
                      <div className="w-100 login-panel-header">
                        <h4 className="text-uppercase text-dark fw-bold heading-text auth-header">{title}</h4>
                        {
                          typeof subtitle === 'string' ?
                            <p className="text-muted">{subtitle}</p> :
                            subtitle
                        }
                      </div>
                      
                    </>
                    : null
                }

                <div className="w-100 auth-form">
                  {props.children}
                </div>
              </>
          }
        </div>
      </Col>
      {
        !trial ?
          <Col className={`col-12 login-background d-block position-absolute ${lite ? 'travel-lite' : ''}`}></Col>
          : null
      }
    </Row>
  )
}

export default AuthLayout