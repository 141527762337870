import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import { Button, Col, Row } from 'react-bootstrap'
import LoadingButton from '@/shared/components/LoadingButton'
import React, {useEffect, useState} from 'react'
import AppModal from '@/shared/components/AppModal'
import LOCATION from '@/constants/Location'
import moment from 'moment'
import { Link } from 'react-router-dom'

function ManageUserSubscription () {
  const { t } = useTranslation()
  const auth = useAuth()

  const [subscribing, setSubscribing] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [requestCancel, setRequestCancel] = useState(false)
  const [subscriptionCanceled, setSubscriptionCanceled] = useState(false)

  const [modalTitle, setModalTitle] = useState(null)
  const [modalBody, setModalBody] = useState(null)
  const [confirmText, setConfirmText] = useState(null)
  const [cancelText, setCancelText] = useState(null)
    const [trialAllowed, setTrialAllowed] = useState(false)

    useEffect(() => {
        auth.getRequest(`${LOCATION.SUBSCRIPTION_PRODUCTS.API.path}`, {
            allow_trial: true
        }).then(response => {
            setTrialAllowed(response.data.length > 0)
        });
    }, []);

  const handleConfirm = () => {
    setShowModal(false)

    if (requestCancel) {
      cancelSubscription()
    }

    if( subscriptionCanceled ){
      window.location.reload()
    }
  }

  const handleCancel = () => {
    setShowModal(false)
  }

  const requestCancelSubscription = () => {
    setModalTitle(t('pages.payments.notifications.cancel_subscription.title'))
    setModalBody(t('pages.payments.notifications.cancel_subscription.body'))
    setConfirmText(t('common.dialogs.actions.yes'))
    setCancelText(t('common.dialogs.actions.no'))
    setShowModal(true)
    setRequestCancel(true)
  }

  const cancelSubscription = () => {

    setSubscribing(true)

    auth.postRequest(`${LOCATION.SUBSCRIPTIONS.API.path}/${auth.user.id}/cancel`)
      .then(response => {
        setSubscriptionCanceled(true)
        setSubscribing(false)

        setModalTitle(t('pages.payments.notifications.subscription_canceled.title'))
        setModalBody(null)
        setConfirmText(t('common.dialogs.actions.okay'))
        setCancelText(null)
        setShowModal(true)
      })
      .catch(error => {
        setSubscribing(false)
      })
  }

  return (
    <>
      <AppModal
        show={showModal}
        title={modalTitle}
        body={modalBody}
        confirmText={confirmText}
        cancelText={cancelText}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
      <div className="mt-3 pt-3">
        {
          auth.user?.subscription && !(auth.user.subscription.canceled_at || auth.user.subscription.status == 2) ?
            <>
              <Row>
                <Col className="col-6">
                  <h6 className="mt-3">{t('common.subscription')}</h6>
                  <h5
                    className="fw-bold">{auth.user?.subscription.is_trial ? t(`common.subscriptions.types.travel_max_trial`) : t(`common.subscriptions.types.${auth.user?.user_type}`)}</h5>
                </Col>
                <Col className="col-6">
                  <h6 className="mt-3">{t('common.valid_until')}</h6>
                  <div className="d-flex align-items-end">
                    <h5
                      className={`fw-bold mb-0 ${ auth.user.subscription.canceled_at ? 'text-danger': ''}`}>
                      {
                        auth.user?.subscription.is_trial ?
                          moment(auth.user?.subscription.trial_ends_at).format(t('common.formats.short_date'))
                          :
                          moment(auth.user?.subscription.valid_until).format(t('common.formats.short_date'))
                      }
                    </h5>
                  </div>
                </Col>
                <Col className="col-6">
                  <h6 className="mt-3">{t('common.cost')}</h6>
                  <h5 className="fw-bold">
                    {
                          auth?.user?.subscription?.subscription_product_promotion?.price ?
                              (
                                    <>
                                        On promotion discount at: {auth.user.currency.symbol}{auth.user.subscription.subscription_product_promotion.price.toFixed(2)}
                                        <br/>
                                    </>
                              )
                          :
                          null
                    }
                    {
                      auth.user?.subscription.is_trial ?
                        t('pages.payments.subscription_price_per_year_trail', {
                          price: auth.user?.subscription.price.toFixed(2),
                          currency: auth.user.currency.symbol
                        })
                        :
                        t('pages.payments.subscription_price_per_year', {
                          price: auth.user?.subscription.price.toFixed(2),
                          currency: auth.user.currency.symbol
                        })
                    }
                  </h5>
                </Col>
                <Col className="col-6">
                  <h6 className="mt-3">{t('common.start_date')}</h6>
                  <h5
                    className="fw-bold">{moment(auth.user?.subscription.created_at).format(t('common.formats.short_date'))}</h5>
                </Col>
              </Row>
              <Row>
                <Col className="col-12 d-grid gap-2">
                  <LoadingButton
                    loading={subscribing}
                    onSubmit={requestCancelSubscription}
                    variant="danger"
                    className="heading-text text-white"
                    titleTranslationKey={t('common.buttons.cancel')}
                  />
                </Col>
              </Row>
            </>
            :
            <>
              <h5 className="heading-text mt-3 mb-4">{t('common.subscription')}</h5>
              <p className="text-danger my-4">{t('common.no_active_subscription')}</p>
              <Row className="my-4">
                <Col className="col-12 d-grid gap-2">
                  <Link
                    className="btn btn-primary heading-text"
                    to={`${LOCATION.PAYMENT_METHODS.CREATE.path}/subscribe/0`}>
                    {t('common.buttons.subscribe')}
                  </Link>

                  {
                    !auth.user?.claimed_subscription_trial && trialAllowed ?
                      <Link
                        className="btn btn-dark text-white heading-text"
                        to={`${LOCATION.PAYMENT_METHODS.CREATE.path}/subscribe/1`}>
                        {t('common.buttons.try_subscription')}
                      </Link>
                      : null
                  }
                </Col>
              </Row>
            </>
        }
      </div>
    </>
  )
}

export default ManageUserSubscription