import Logo from '@/shared/components/Logo'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { useAuth } from '@/services/Auth'
import UserProfileDropDown from './components/UserProfileDropDown'
import LOCATION from '../constants/Location'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faBell, faXmark } from '@fortawesome/free-solid-svg-icons'
import { Button, Dropdown } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import AppModal from '../shared/components/AppModal'

function Navbar ({ handleToggleSidebar, toggled, history }) {
  const auth = useAuth()
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const location = useLocation()

  const notification = auth.newNotification
  const notifications = auth.notifications

  const travelMumWebsiteUr = process.env.REACT_APP_TRAVEL_MUM_WEBSITE_URL

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [modalBody, setModalBody] = useState(null)
  const [confirmText, setConfirmText] = useState(null)
  const [cancelText, setCancelText] = useState(null)
  const [dealsFound, setDealsFound] = useState(false)
  const [tripRequestId, setTripRequestId] = useState(null)

  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const checkDeals = (notification) => {
    if( notification.canceled ){

      setModalTitle(t('pages.deals.notifications.no_deal_found_on_request_limit_reached.title'))
      setModalBody(t('pages.deals.notifications.no_deal_found_on_request_limit_reached.body'))
      setConfirmText(t('common.dialogs.actions.okay'))
      setCancelText(null)
      setShowModal(true)

      return  null;
    }
    return auth.getRequest(`${LOCATION.DEALS.API.path}`, { trip_request_id: notification.trip_request_id })
      .then(response => {
        if (response.data.length) {
          setModalTitle(t('pages.deals.notifications.deals_found.title'))
          setModalBody(t('pages.deals.notifications.deals_found.body'))
          setConfirmText(t('common.dialogs.actions.take_me_there'))
          setCancelText(t('common.dialogs.actions.okay'))
          setDealsFound(true)
        } else {

          setModalTitle(t('pages.deals.notifications.no_deal_found_on_request.title'))
          setModalBody(t('pages.deals.notifications.no_deal_found_on_request.body'))
          setConfirmText(t('common.dialogs.actions.okay'))
          setCancelText(null)
        }
        setShowModal(true)
      })
  }

  useEffect(() => {
    if (notification && notification?.event_type === 'deal_request_response' && notification.tripRequest.search_completed && location.pathname !== `${LOCATION.TRIPS.START_YOUR_JOURNEY.path}/summary` && location.pathname !== LOCATION.DEALS.SEARCH.path) {
      setTripRequestId(notification.trip_request_id)
      auth.clearNotification()
      let request = checkDeals(notification)
      return () => request?.abort && request.abort()
    }
  }, [notification])

  useEffect(() => {
    setShowMobileMenu(false)
  }, [pathname])

  const handleConfirm = () => {
    setShowModal(false)
    if (dealsFound) {
      if (auth.user?.admin) {
        window.location.href = `${LOCATION.DEALS.LIST.path}/search/${tripRequestId}`
      }
      else{
        window.location.href = `${LOCATION.TRIPS.START_YOUR_JOURNEY.path}/results/${tripRequestId}`
      }
    }
  }

  const handleCancel = () => {
    setShowModal(false)
  }

  const menuDropdown = React.forwardRef(({ children, onClick }, ref) => (
    <FontAwesomeIcon
      role="button"
      ref={ref}
      onClick={onClick}
      icon={faBars}
    />
  ))

  function Links () {
    return (
      <>
        <Dropdown.Item
          className="heading-text text-nowrap nav-link mx-2 px-2 py-lg-0 py-2"
          href="/"
        >
          {
            auth.user.user_type === 'travel_max' ? <span>{t('pages.travel_max_area.title')}</span> :
              <span>{t('pages.travel_max_area.travel_lite_area')}</span>
          }
        </Dropdown.Item>
        <Dropdown.Item className="heading-text text-nowrap nav-link mx-2 px-2 py-lg-0 py-2"
                       href={LOCATION.DEALS.TOP_15.path}>{t('pages.deals.top_15')}</Dropdown.Item>
        <Dropdown.Item className="heading-text text-nowrap nav-link mx-2 px-2 py-lg-0 py-2"
                       href={travelMumWebsiteUr + '/cheap-package-deals/'}>
          Package Deals
        </Dropdown.Item>
        <Dropdown.Item className="heading-text text-nowrap nav-link mx-2 px-2 py-lg-0 py-2"
                       href={travelMumWebsiteUr + '/locations/'}>
          Locations
        </Dropdown.Item>
        <Dropdown.Item className="heading-text text-nowrap nav-link mx-2 px-2 py-lg-0 py-2"
                       href={travelMumWebsiteUr + '/insights/'}>
          Blog
        </Dropdown.Item>
      </>
    )
  }

  return (
    <>
      <AppModal
        show={showModal}
        title={modalTitle}
        body={modalBody}
        confirmText={confirmText}
        cancelText={cancelText}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
      <nav className="main-navbar navbar fixed-top navbar-light bg-white m-0 navbar-new-layout">
		<div className="container">
          <div className="d-flex align-items-center">
            {
              auth?.user?.admin &&
              <Button className="icon-btn btn-toggle me-4 elevated" variant="primary"
                      onClick={() => handleToggleSidebar(!toggled)}>
                {
                  toggled ?
                    <FontAwesomeIcon size="xl" icon={faXmark}/>
                    :
                    <FontAwesomeIcon size="xl" icon={faBars}/>
                }
              </Button>
            }
            <a href={travelMumWebsiteUr} className="btn btn-icon p-0">
              <Logo
                width={155}
                // height={45}
              />
            </a>
          </div>
          {
            auth?.user ?
              <div className="d-flex">
                {
                  !auth?.user?.admin &&
                  <div className="d-flex align-items-center">
                    <div className="navbar-nav flex-row align-items-center d-xl-flex d-none">
                      <Links/>
                    </div>
                    <Link to={LOCATION.NOTIFICATIONS.LIST.path} className="position-relative notification-bar">
                      {
                        notifications?.length !== 0?
                          <div className="rounded-circle notification-badge">{notifications.length}</div> : null
                      }
                      <FontAwesomeIcon className="text-dark" size="lg" icon={faBell}/>
                    </Link>
                    <UserProfileDropDown/>

                    <div className="ms-2 elevated d-xl-none d-block">
                      {
                        showMobileMenu ?
                          <FontAwesomeIcon role="button" onClick={() => setShowMobileMenu(!showMobileMenu)} size="xl"
                                           icon={faXmark}/>
                          :
                          <FontAwesomeIcon role="button" onClick={() => setShowMobileMenu(!showMobileMenu)} size="xl"
                                           icon={faBars}/>
                      }
                    </div>
                  </div>
                }
              </div>
              :
              <div className="navbar-nav text-uppercase flex-row align-items-center d-md-flex d-none">
                <Link className="heading-text mx-2 px-2 text-dark" to={LOCATION.DEALS.TOP_15.path}>{t('pages.deals.top_15')}</Link>
                <Dropdown.Item className="heading-text text-nowrap nav-link mx-2 px-2 py-lg-0 py-2"
                               href={travelMumWebsiteUr + '/cheap-package-deals/'}>
                  Package Deals
                </Dropdown.Item>
                <Dropdown.Item className="heading-text text-nowrap nav-link mx-2 px-2 py-lg-0 py-2"
                               href={travelMumWebsiteUr + '/locations/'}>
                  Locations
                </Dropdown.Item>
                <Dropdown.Item className="heading-text text-nowrap nav-link mx-2 px-2 py-lg-0 py-2"
                               href={travelMumWebsiteUr + '/about-us/'}>
                  About Us
                </Dropdown.Item>
                <Dropdown.Item className="heading-text text-nowrap nav-link mx-2 px-2 py-lg-0 py-2"
                               href={travelMumWebsiteUr + '/insights/'}>
                  Blog
                </Dropdown.Item>
				<div className="d-flex header-btn-group">
                <Link className="btn btn-primary heading-text text-nowrap" to="/trips/start-your-journey">Get Started</Link>
                <Link className="btn btn-secondary heading-text text-nowrap btn-w-icon" to="/login"><svg enable-background="new 0 0 24 24" id="Layer_1" version="1.0" viewBox="0 0 24 24"><circle cx="12" cy="8" r="4"/><path d="M12,14c-6.1,0-8,4-8,4v2h16v-2C20,18,18.1,14,12,14z"/></svg> {t('pages.login.title')}</Link>
				</div>
              </div>
          }
		  </div>
        
      </nav>

      <div className={`mobile-menu-cover d-xl-none ${showMobileMenu ? 'show' : ''}`}>

        {
          !auth?.user ?
            <>
              <Link className="heading-text mx-2 px-2 text-dark" to={LOCATION.DEALS.TOP_15.path}>{t('pages.deals.top_15')}</Link>
              <Link className="heading-text mx-2 px-2 text-dark" to="/login">{t('pages.login.title')}</Link>
              <Link className="heading-text mx-2 px-2 text-dark" to="/sign-up">{t('pages.signup.link_title')}</Link>
            </>
            :
            <Links/>
        }
      </div>
    </>
  )
}

export default Navbar