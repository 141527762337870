import {useAuth} from '../../services/Auth'
import {useTranslation} from 'react-i18next'
import React, {useRef, useState, useEffect} from 'react'
import * as yup from 'yup'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import AuthLayout from './AuthLayout'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck} from '@fortawesome/free-solid-svg-icons'
import {Link, useParams} from 'react-router-dom'
import {Button, Form, Col, Row} from 'react-bootstrap'
import LoadingButton from '@/shared/components/LoadingButton'
import useGet from '@/hooks/useGet'
import LoadingCover from '@/shared/components/LoadingCover'
import LOCATION from '@/constants/Location'
import {formatRecord} from '@/utility'
import AirportSelection from '../Trips/Request/components/AirportSelection'
import ReCAPTCHA from 'react-google-recaptcha'
import TrialBenefits from '../Banners/TrialBenefits'
import Location from '../../constants/Location'

function SignUp() {

    const auth = useAuth()
    const {t} = useTranslation()
    const [signingUp, setSigningUp] = useState(false)
    const [trialAllowed, setTrialAllowed] = useState(false);

    const recaptchaRef = useRef()
    const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY

    const intended = localStorage.getItem('intended');

    const {max} = useParams()

    const [serverError, setServerError] = useState('')
    const [serverErrors, setServerErrors] = useState([])
    const [selectedAirport, setSelectedAirport] = useState(null)
    const [requestValues, setRequestValues] = useState(null)
    const [travelMaxTrial, setTravelMaxTrial] = useState(false)
    const [trialLoading, setTrialLoading] = useState(false)
    const [loading, setLoading] = useState(true)

    const requestStore = useRef({})
    const {current: requests} = requestStore

    const submitRef = useRef(null)

    let signUpInitialValues = localStorage.getItem('sign_up_values')
    let initialValues = max === 'trial' && signUpInitialValues ? JSON.parse(signUpInitialValues) : {}

    let passwordMinLength = 6;

    useEffect(() => {
        auth.getRequest(`${LOCATION.SUBSCRIPTION_PRODUCTS.API.path}`, {
            allow_trial: true
        }).then(response => {
            if (max === 'trial' && response.data.length === 0) {
                window.location.href = '/sign-up/travel-max';
            }

            setTrialAllowed(response.data.length > 0)
        });
    }, []);

    const schema = yup.object().shape({
        first_name: yup.string()
            .required(t('common.form_validation.first_name_is_required')),
        last_name: yup.string()
            .required(t('common.form_validation.last_name_is_required')),
        where_from: yup.array()
            .min(1, t('pages.trips.form.validation_message.choose_one_airport'))
            .of(yup.object().shape({
                value: yup.number()
                    .required(t('pages.trips.form.validation_message.is_required', {attribute: t('pages.trips.form.labels.where_from.title')})),
                label: yup.string(),
            })),
        email: yup.string()
            .email(t('common.form_validation.invalid_email_address'))
            .required(t('common.form_validation.is_required', {attribute: t('common.form.email')})),
        password: yup.string()
            .min(passwordMinLength, t('common.form_validation.password_invalid_min_length', {length: passwordMinLength}))
            .required(t('common.form_validation.password_is_required')),
        password_confirmation: yup.string().oneOf([yup.ref('password'), null], t('common.form_validation.password_is_must_match'))
            .required(t('common.form_validation.password_confirm_required')),
    })

    const {
        handleSubmit,
        register,
        unregister,
        setValue,
        getValues,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: (_ => {
            return {
                first_name: initialValues?.first_name,
                last_name: initialValues?.last_name,
                email: initialValues?.email,
                password: initialValues?.password,
                password_confirmation: initialValues?.password_confirmation,
                where_from: initialValues?.where_from,
            }
        })(),
    })

    const signUp = async (values) => {

        setServerError('')
        setSigningUp(true)


        localStorage.removeItem('sign_up_values');

        const token = await recaptchaRef.current.executeAsync()
        values.airports = values.where_from?.map(_ => _.value)
        values.max_user_request = max;

        if (token) {

            auth.signUp(values)
                .then(response => {
                    setSigningUp(false)
                    setTrialLoading(false)

                    if (max || travelMaxTrial) {
                        if (max === 'trial' || travelMaxTrial) {
                            window.location.href = `${LOCATION.PAYMENT_METHODS.CREATE.path}/subscribe/1`
                        } else {
                            window.location.href = `${LOCATION.PAYMENT_METHODS.CREATE.path}/subscribe/0`
                        }
                    } else if (requestValues) {
                        window.location.href = `${LOCATION.TRIPS.START_YOUR_JOURNEY.path}/summary`
                    } else if (intended) {
                        localStorage.removeItem('intended');
                        window.location.href = intended;
                    } else {
                        window.location.href = '/'
                    }
                })
                .catch(error => {
                    setSigningUp(false)
                    setTrialLoading(false)
                    setTravelMaxTrial(false)
                    if (error?.response?.status === 401) {
                        setServerError(error.response.data.message)
                    }

                    if (error.response.status === 422) {
                        let serverErrors = []
                        for (const key in error.response.data.errors) {
                            serverErrors.push(
                                error.response.data.errors[key][0]
                            )
                        }
                        setServerErrors(serverErrors)
                    }
                })
        } else {
            setServerError(t('common.errors.recaptcha_error'))
        }
    }

    const tryTravelMax = () => {
        setTravelMaxTrial(true)
        setTrialLoading(true)

        localStorage.setItem('sign_up_values', JSON.stringify(getValues()))

        window.location.href = `${Location.AUTH.SIGN_UP.path}/trial`
    }

    const getAirports = (search) => {

        requests.airports && requests?.airports?.abort && requests.airports.abort()

        return new Promise((resolve, reject) => {
            requests.airports = auth.getRequest(LOCATION.AIRPORTS.API.path, {search})

            requests.airports.then(response => {
                resolve(response.data?.data.map(formatRecord))
            })
                .catch(error => reject(error))
        })
    }

    useEffect(() => {
        let requestData = localStorage.getItem('request_data')
        if (requestData) {
            setRequestValues(JSON.parse(requestData))
        }

    }, [])

    return (
	
		<div className="travel-max-page float-left w-100 h-auto d-block">
		
			<div className="float-left w-100 h-auto travel-max-hero-mobile d-none" style={{
                    backgroundImage: 'url(/assets/images/ttm-image-low-res.jpg)',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                }}></div>
				
			<div className="float-left w-100 h-auto position-relative travel-max-hero" style={{
                    backgroundImage: 'url(/assets/images/ttm-image-low-res.jpg)',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
					backgroundPosition: 'center center'
                }}>

                    <div className="container position-relative">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <h2 className="heading-2">Free Sign Up, <br />Holiday Club Sneak Peek</h2>
                                <ul className="checklist">
                                    <li>Use our free DIY Trip Builder to find the best deals.</li>
                                    <li>Save a deal for later.</li>
                                    <li><a href="/sign-up/travel-max">Travel deals straight to your inbox</a></li>
                                </ul>
                                <p>(Get 5x more when you join our Holiday Club)</p>
                            </div>
                        </div>
                    </div>
                </div>
				
				
				
				
				
				<div className="float-left w-100 h-auto position-relative travel-max-subscribe travel-lite-subscribe">

                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="float-left w-100 h-auto travel-max-subscribe-block">
                                    <h6 className="text-uppercase text-dark fw-bold heading-text float-left w-100">Sign Up</h6>
                                    <Form noValidate onSubmit={handleSubmit(signUp)}>
                                       <Form.Group className="form-group-new" controlId="formBasicFirstName">
											
											<Form.Control
												type="text"
												placeholder={t('common.form.placeholder_enter', {attribute: t('common.form.first_name')})}
												{...register('first_name')}
												isInvalid={!!errors.first_name}
											/>
					
											<Form.Control.Feedback type="invalid">
												{errors.first_name && errors.first_name.message}
											</Form.Control.Feedback>
										</Form.Group>
										<Form.Group className="form-group-new" controlId="formBasicLastName">
											
											<Form.Control
												type="text"
												placeholder={t('common.form.placeholder_enter', {attribute: t('common.form.last_name')})}
												{...register('last_name')}
												isInvalid={!!errors.last_name}
											/>
					
											<Form.Control.Feedback type="invalid">
												{errors.last_name && errors.last_name.message}
											</Form.Control.Feedback>
										</Form.Group>
										<Form.Group className="form-group-new" controlId="formBasicEmail">
											
											<Form.Control
												type="email"
												placeholder={t('common.form.placeholder_enter', {attribute: t('common.form.email')})}
												{...register('email')}
												isInvalid={!!errors.email}
											/>
					
											<Form.Control.Feedback type="invalid">
												{errors.email && errors.email.message}
											</Form.Control.Feedback>
										</Form.Group>
					
										<Form.Group className="form-group-new select-2" controlId="formBasicAirport">
											
											<AirportSelection
												errors={errors}
												register={register}
												unregister={unregister}
												setValue={setValue}
												controlled={true}
												selectedAirports={initialValues.where_from}
												filter="countries"
												 selectClassName="travel-max-signup-airport-select"
											/>
										</Form.Group>
					
										<Form.Group className="form-group-new" controlId="formBasicPassword">
											
											<Form.Control
												type="password"
												placeholder={t('common.form.password')}
												{...register('password')}
												isInvalid={!!errors.password}
											/>
											<Form.Control.Feedback type="invalid">
												{errors.password && errors.password.message}
											</Form.Control.Feedback>
										</Form.Group>
					
										<Form.Group className="form-group-new" controlId="formBasicConfirmPassword">
											
											<Form.Control
												type="password"
												placeholder={t('common.form.re_enter_password')}
												{...register('password_confirmation')}
												isInvalid={!!errors.password_confirmation}
											/>
											<Form.Control.Feedback type="invalid">
												{errors.password_confirmation && errors.password_confirmation.message}
											</Form.Control.Feedback>
										</Form.Group>
										{
											serverError !== '' &&
											<div className="form-group mb-4">
												<p className="text-danger py-1 mb-0">{t(serverError)}</p>
											</div>
										}
										{
											(serverErrors.length !== 0) &&
											<div className="form-group mb-4">
												{
													serverErrors.map((error, index) =>
														<p className="text-danger font-weight-bold"
														   key={index}>{t(error)}</p>)
												}
											</div>
										}
                                        <Form.Group className="form-group-new login-button">
                                             <ReCAPTCHA
												ref={recaptchaRef}
												size="invisible"
												sitekey={RECAPTCHA_KEY}
											/>
                                            <LoadingButton
                                                clickRef={submitRef}
                                                className="btn btn-primary icon-only-btn"
                                                loading={signingUp}
                                                variant="dark"
                                                titleTranslationKey={max ? t('pages.signup.title_max') : t('pages.signup.title_lite')}
                                                type="submit"
                                            />
                                        </Form.Group>
                                    </Form>

                                    <p>{t('pages.signup.already_have_an_account_login')} <Link to="/login" className="btn-link" >{t('pages.login.title')}</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
		
		
		</div>
		
		
		
		
		
		
	

    )
}

function MemberBenefits() {
    const {t} = useTranslation()

    const {max} = useParams()

    const TravelMaxPriceYearly = 30
    return (
        <>
            {
                max ?
                    max === 'trial' ?
                        <TrialBenefits/>
                        :
                        <>
                            <h5 className="my-3 heading-text text-primary head">
                                {t('common.travel_max_feature.price_title', {
                                    price: TravelMaxPriceYearly.toFixed(2),
                                    currency: '£'
                                })}
                            </h5>
                            <ul className="list-group list-group-flush benefits-list">
                                <li className="list-group-item">
                                    <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                                    Personalised deals updated weekly.
                                </li>
                                <li className="list-group-item">
                                    <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                                    Save and monitor multiple deals.
                                </li>
                                <li className="list-group-item">
                                    <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                                    Be notified when your favourite trips change in price.
                                </li>
                                <li className="list-group-item">
                                    <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                                    Weekly deals under £200
                                </li>
                                <li className="list-group-item">
                                    <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                                    Expert support with your travel planning.
                                </li>
                                <li className="list-group-item">
                                    <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                                    And much more!
                                </li>
                            </ul>
                        </>
                    :
                    <ul className="list-group list-group-flush benefits-list">
                        <li className="list-group-item">
                            <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                            Customise your own DIY deals in 30 seconds.
                        </li>
                        <li className="list-group-item">
                            <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                            Save a search result for later.
                        </li>
                        <li className="list-group-item">
                            <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                            Travel deals sent straight to your inbox.
                        </li>
                    </ul>
            }
        </>

    )
}

export default SignUp