import Login from '@/pages/auth/Login'
import SignUp from '@/pages/auth/SignUp'
import LOCATION from '../constants/Location'
import ForgotPassword from '@/pages/auth/ForgotPassword'
import ResetPasswordForm from '@/pages/auth/ResetPasswordForm'
import TravelMaxPremium from '../pages/Banners/TravelMaxPremium'
import Unsubscribe from '../pages/auth/Unsubscribe'
import Top15Deals from '@/pages/Deals/Top15Deals'
import SignUpTravelMax from "@/pages/auth/SignUpTravelMax";

const Public = [
  {
    path: LOCATION.DEALS.TOP_15.path,
    component: Top15Deals,
    public: true,
    authVisit: true,
    exact: true,
  },
  {
    path: LOCATION.AUTH.SIGN_IN.path,
    component: Login,
    public: true,
    authVisit: false,
    exact: true,
  },
  {
    path: LOCATION.AUTH.SIGN_IN_WITH_EMAIL.path,
    component: Login,
    public: true,
    authVisit: false,
    exact: true,
  },
  {
    path: LOCATION.AUTH.SIGN_UP.path,
    component: SignUp,
    public: true,
    authVisit: false,
    exact: true,
  },
  {
    path: LOCATION.AUTH.SIGN_UP_TRAVEL_MAX.path,
    component: SignUpTravelMax,
    public: true,
    authVisit: false,
    exact: true,
  },
  {
    path: LOCATION.AUTH.FORGOT_PASSWORD.path,
    component: ForgotPassword,
    public: true,
    authVisit: false,
    exact: true,
  },
  {
    path: LOCATION.AUTH.RESET_PASSWORD.path,
    component: ResetPasswordForm,
    public: true,
    authVisit: false,
    exact: true,
  },
  {
    path: LOCATION.BANNERS.TRAVEL_MAX_UPSELL.path,
    component: TravelMaxPremium,
    public: true,
    authVisit: true,
    exact: true,
  },
  {
    path: LOCATION.AUTH.UNSUBSCRIBE.path,
    component: Unsubscribe,
    public: true,
    authVisit: true,
    exact: true,
  },
]

export default Public
