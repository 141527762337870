import { Col, Row } from 'react-bootstrap'
import OnPageNotification from '@/shared/components/OnPageNotification'

function SignupLayout ({
  title,
  subtitle,
  showNotification,
  notificationTitle,
  notificationDescription,
  notificationLink,
  notificationLinkText,
  lite,
  trial,
  ...props
}) {
  return (
    <Row className="" style={{ height: '100%' }}>
      {props.children}
    </Row>
  )
}

export default SignupLayout