import Carousel from 'react-bootstrap/Carousel';
import {useAuth} from '../../services/Auth'
import {useTranslation} from 'react-i18next'
import React, {useRef, useState, useEffect} from 'react'
import * as yup from 'yup'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import SignupLayout from './SignupLayout'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck, faPlaneDeparture} from '@fortawesome/free-solid-svg-icons'
import {Link, useParams} from 'react-router-dom'
import {Button, Form} from 'react-bootstrap'
import LoadingButton from '@/shared/components/LoadingButton'
import useGet from '@/hooks/useGet'
import LoadingCover from '@/shared/components/LoadingCover'
import LOCATION from '@/constants/Location'
import {formatRecord} from '@/utility'
import AirportSelection from '../Trips/Request/components/AirportSelection'
import ReCAPTCHA from 'react-google-recaptcha'
import TrialBenefits from '../Banners/TrialBenefits'
import Location from '../../constants/Location'

function SignUpTravelMax() {

    const auth = useAuth()
    const {t} = useTranslation()
    const [signingUp, setSigningUp] = useState(false)

    const recaptchaRef = useRef()
    const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY

    const [serverError, setServerError] = useState('')
    const [serverErrors, setServerErrors] = useState([])
    const [requestValues, setRequestValues] = useState(null)

    const requestStore = useRef({})
    const {current: requests} = requestStore

    const submitRef = useRef(null)

    let initialValues = {};

    let passwordMinLength = 6;

    const schema = yup.object().shape({
        first_name: yup.string()
            .required(t('common.form_validation.first_name_is_required')),
        last_name: yup.string()
            .required(t('common.form_validation.last_name_is_required')),
        where_from: yup.array()
            .min(1, t('pages.trips.form.validation_message.choose_one_airport'))
            .of(yup.object().shape({
                value: yup.number()
                    .required(t('pages.trips.form.validation_message.is_required', {attribute: t('pages.trips.form.labels.where_from.title')})),
                label: yup.string(),
            })),
        email: yup.string()
            .email(t('common.form_validation.invalid_email_address'))
            .required(t('common.form_validation.is_required', {attribute: t('common.form.email')})),
        password: yup.string()
            .min(passwordMinLength, t('common.form_validation.password_invalid_min_length', {length: passwordMinLength}))
            .required(t('common.form_validation.password_is_required')),
        password_confirmation: yup.string().oneOf([yup.ref('password'), null], t('common.form_validation.password_is_must_match'))
            .required(t('common.form_validation.password_confirm_required')),
    })

    const {
        handleSubmit,
        register,
        unregister,
        setValue,
        getValues,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: (_ => {
            return {
                first_name: initialValues?.first_name,
                last_name: initialValues?.last_name,
                email: initialValues?.email,
                password: initialValues?.password,
                password_confirmation: initialValues?.password_confirmation,
                where_from: initialValues?.where_from,
            }
        })(),
    })

    const signUp = async (values) => {

        setServerError('')
        setSigningUp(true)


        localStorage.removeItem('sign_up_values');

        const token = await recaptchaRef.current.executeAsync()
        values.airports = values.where_from?.map(_ => _.value)
        values.max_user_request = true;

        if (token) {

            auth.signUp(values)
                .then(response => {
                    setSigningUp(false);

                    window.location.href = `${LOCATION.PAYMENT_METHODS.CREATE.path}/subscribe/0`;
                })
                .catch(error => {
                    setSigningUp(false);

                    if (error?.response?.status === 401) {
                        setServerError(error.response.data.message)
                    }

                    if (error.response.status === 422) {
                        let serverErrors = []
                        for (const key in error.response.data.errors) {
                            serverErrors.push(
                                error.response.data.errors[key][0]
                            )
                        }
                        setServerErrors(serverErrors)
                    }
                })
        } else {
            setServerError(t('common.errors.recaptcha_error'))
        }
    }

    useEffect(() => {
        let requestData = localStorage.getItem('request_data')
        if (requestData) {
            setRequestValues(JSON.parse(requestData))
        }

    }, [])

    const reviews = [
        {
            name: "HR",
            content: "I love receiving your emails, there’s always great deals. I especially love the ⅔ nights short stay deals that you find for us! Thank you!"
        },
        {
            name: "MB",
            content: "Keep up the good work - thanks to you, I've visited places I'd never have dreamed of going to, and even done some solo city breaks!"
        },
        {
            name: "HS",
            content: "Thank you for doing such a fantastic job! You’ve made me feel like it is possible for us to afford to travel and given us the opportunity to experience things as a family I never thought we would have. I can’t wait to book our first family holiday!"
        },
        {
            name: "SY",
            content: "I love your emails. We are a family of 5 2 adult and 3 children under 5 and I can never seem to find decent holidays that don't cost a fortune especially in the holidays plus that are safe for the children. Your family is so lovely and you both do an amazing job so never forget that!"
        },
        {
            name: "AF",
            content: "You have opened our eyes to travel with children which we have shied away from previously. You are giving us loads of inspiration."
        },
        {
            name: "LJ",
            content: "I absolutely love your website, I’ve booked 4 holiday over the last two years using your site and they’ve been amazing and so budget friendly. The DIY trip builder feature is my favorite aspect, it allows me to fully customise my holiday and find something perfect every time! I’ll be booking my next one soon"
        },
        {
            name: "RW",
            content: "Thank you for making travel more accessible for our family I love looking through your emails."
        },
        {
            name: "AT",
            content: "I love your family and what you're doing to change the travel world. You've made me understand how it is so much easier than we think it is and it's all about mindset and looking out for good deals that you're so helpful with too."
        },
        {
            name: "Luke",
            content: "Your website has inspired me and my partner to go on so many trips around Europe. I never realised how cheap it could be when shopping around!"
        }
    ]

    function chunkArray(arr, size) {
        const result = [];
        for (let i = 0; i < arr.length; i += size) {
            result.push(arr.slice(i, i + size));
        }
        return result;
    }

    return (
        <SignupLayout
            title={t('pages.signup.title_max')}
            // lite={false}
            // trial={false}
        >
            <div className="travel-max-page float-left w-100 h-auto d-block">

                <div className="float-left w-100 h-auto travel-max-hero-mobile d-none" style={{
                    backgroundImage: 'url(/assets/images/family.jpg)',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                }}></div>

                <div className="float-left w-100 h-auto position-relative travel-max-hero" style={{
                    backgroundImage: 'url(/assets/images/family.jpg)',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                }}>

                    <div className="container position-relative">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <h2 className="heading-2">The Travel Mum Holiday Club</h2>
                                <ul className="checklist">
                                    <li>Live Holiday Deal alerts to your inbox.</li>
                                    <li>5x more personalised holiday deals</li>
                                    <li>DIY weekend trip alerts</li>
                                    <li>Set your school holiday dates</li>
                                    <li>Exclusive discounts</li>
                                    <li>And much more!</li>
                                </ul>
                                <a className="btn btn-primary heading-text" onClick={(e) => {
                                    e.preventDefault();
                                    document.getElementById("sign-up-form").scrollIntoView({
                                        behavior: "smooth",
                                        block: "end",
                                        inline: "nearest"
                                    });
                                }}>Join the Club!</a>
                                <p>Already have an account? <a href="/login" className="btn-link">Log In Here</a></p>
                                <h5 className="text-uppercase text-dark fw-bold">Let us do the hard work for you</h5>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="float-left w-100 h-auto position-relative travel-max-checklists">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="float-left w-100 h-auto text-center">
                                    <h2 className="heading-3">THE BEST VALUE HOLIDAY CLUB!<br/>
                                        <span className="text-yellow">For just £30.00 for the whole year!</span>
                                    </h2>
                                </div>

                                <ul className="checklist columned">
                                    <li>
                                        Tell us your preferred airports, number of travellers and set your school holiday dates (if needed) so we can alert you to great deals that work for you 🚨
                                    </li>
                                    <li>
                                        We will help you travel for less with weekly DIY trip ideas including great weekend flight deals and long haul trips ✈️
                                    </li>
                                    <li>
                                        Use our DIY trip builder and save your finds to monitor prices. 💰
                                    </li>
                                    <li>
                                        Exclusive hotel recommendations updated frequently (eg hotels with heated pools in April, hotels with lazy rivers, hotels with the best kids clubs)
                                    </li>
                                    <li>
                                        Exclusive deals just for our club members.
                                    </li>
                                    <li>
                                        Ask us anything! We are travel experts and are here to help you make travel easy.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="float-left w-100 h-auto position-relative travel-max-subscribe" style={{
                    backgroundImage: 'url(/assets/images/subscribe-image.png',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                }}>

                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="float-left w-100 h-auto travel-max-subscribe-block">
                                    <h6 className="text-uppercase text-dark fw-bold heading-text float-left w-100">Subscribe</h6>
                                    <Form noValidate onSubmit={handleSubmit(signUp)}>
                                        <Form.Group className="form-group-new" controlId="formBasicFirstName">
                                            <Form.Label
                                                className="fw-bold text-uppercase d-flex align-items-center heading-text">
                                                {t('common.form.first_name')}
                                                <span className="text-danger ms-2 small">*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder={t('common.form.placeholder_enter', {attribute: t('common.form.first_name')})}
                                                {...register('first_name')}
                                                isInvalid={!!errors.first_name}
                                            />

                                            <Form.Control.Feedback type="invalid">
                                                {errors.first_name && errors.first_name.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="form-group-new" controlId="formBasicLastName">
                                            <Form.Label className="fw-bold text-uppercase heading-text">
                                                {t('common.form.last_name')}
                                                <span className="text-danger ms-2 small">*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder={t('common.form.placeholder_enter', {attribute: t('common.form.last_name')})}
                                                {...register('last_name')}
                                                isInvalid={!!errors.last_name}
                                            />

                                            <Form.Control.Feedback type="invalid">
                                                {errors.last_name && errors.last_name.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="form-group-new" controlId="formBasicEmail">
                                            <Form.Label className="fw-bold text-uppercase heading-text">
                                                {t('common.form.email')}
                                                <span className="text-danger ms-2 small">*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder={t('common.form.placeholder_enter', {attribute: t('common.form.email')})}
                                                {...register('email')}
                                                isInvalid={!!errors.email}
                                            />

                                            <Form.Control.Feedback type="invalid">
                                                {errors.email && errors.email.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className="form-group-new select-2" controlId="formBasicAirport">
                                            <Form.Label className="fw-bold text-uppercase heading-text">
                                                {t('common.form.preferred_airport')}
                                                <span className="text-danger ms-2 small">*</span>
                                            </Form.Label>

                                            <AirportSelection
                                                errors={errors}
                                                register={register}
                                                unregister={unregister}
                                                setValue={setValue}
                                                controlled={true}
                                                selectedAirports={initialValues.where_from}
                                                filter="countries"
                                                selectClassName="travel-max-signup-airport-select"
                                            />
                                        </Form.Group>

                                        <Form.Group className="form-group-new" controlId="formBasicPassword">
                                            <Form.Label className="fw-bold text-uppercase heading-text">
                                                {t('common.form.password')}
                                                <span className="text-danger ms-2 small">*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="password"
                                                placeholder={t('common.form.password')}
                                                {...register('password')}
                                                isInvalid={!!errors.password}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.password && errors.password.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className="form-group-new" controlId="formBasicConfirmPassword">
                                            <Form.Label className="fw-bold text-uppercase heading-text">
                                                {t('common.form.re_enter_password')}
                                                <span className="text-danger ms-2 small">*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="password"
                                                placeholder={t('common.form.re_enter_password')}
                                                {...register('password_confirmation')}
                                                isInvalid={!!errors.password_confirmation}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.password_confirmation && errors.password_confirmation.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        {
                                            serverError !== '' &&
                                            <div className="form-group mb-4">
                                                <p className="text-danger py-1 mb-0">{t(serverError)}</p>
                                            </div>
                                        }
                                        {
                                            (serverErrors.length !== 0) &&
                                            <div className="form-group mb-4">
                                                {
                                                    serverErrors.map((error, index) =>
                                                        <p className="text-danger font-weight-bold"
                                                           key={index}>{t(error)}</p>)
                                                }
                                            </div>
                                        }
                                        <Form.Group className="form-group-new login-button">
                                            <ReCAPTCHA
                                                ref={recaptchaRef}
                                                size="invisible"
                                                sitekey={RECAPTCHA_KEY}
                                            />
                                            <LoadingButton
                                                clickRef={submitRef}
                                                className="btn btn-primary icon-only-btn"
                                                loading={signingUp}
                                                variant="dark"
                                                titleTranslationKey={t('pages.signup.title_max')}
                                                type="submit"
                                            />
                                        </Form.Group>
                                    </Form>

                                    <i>*Travel Max is an annual subscription which can be easily cancelled at any time
                                        from the subscription area of your profile</i>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="float-left w-100 h-auto position-relative travel-max-testimonials">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="float-left w-100 h-auto travel-max-reviews-block">
                                    <h2 className="heading-3 float-left w-100 text-left">Reviews</h2>

                                    <div className="row testimonial-row">
                                        <Carousel>
                                            {
                                                chunkArray(reviews, 3).map((reviewGroup, reviewGroupIndex) => (
                                                    <Carousel.Item key={reviewGroupIndex}>
                                                        <div className="row testimonial-row">
                                                            {
                                                                reviewGroup.map((review, reviewIndex) => (
                                                                    <div className="col-12 col-lg-4" key={reviewIndex}>
                                                                        <div
                                                                            className="testimonial-quote float-left w-100 h-100">
                                                                            <div className="quote">
                                                                                &quot;
                                                                            </div>
                                                                            <div className="card-body">

                                                                                <small>
                                                                                    {review.content}
                                                                                </small>
                                                                                <p><strong>{review.name}</strong></p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </Carousel.Item>
                                                ))
                                            }
                                        </Carousel>
                                        <div className="col-12 mt-3">
                                            <a target="_blank" href="https://uk.trustpilot.com/review/www.thetravelmum.com" className="btn btn-primary text-white heading-text">Read More Reviews</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="float-left w-100 h-auto position-relative travel-max-signup" style={{
                    backgroundImage: 'url(/assets/images/explore.png)',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}>
                    <div className="container position-relative">
                        <div className="row">
                            <div className="col-12">
                                <div className="float-left d-flex flex-wrap travel-max-signup-block">
                                    <h2 className="heading-3">Exploring made easy</h2>
                                    <a className="btn btn-light text-dark heading-text" href="/start-your-journey">DIY Trip Builder</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SignupLayout>
    )
}

export default SignUpTravelMax